import React from 'react';
import FileInputItemDeleteCross from './FileInputItemDeleteCross';
import LoadingSpinner from './LoadingSpinner';
import { FileInputItemContainer } from './elements';

const FileInputItem = ({ className, deleteFile, file }) => (
  <FileInputItemContainer status={file.status} className={className}>
    <div css={{ display: 'flex' }}>
      <span>{file.name}</span>
      <span css={{ marginLeft: '18px' }}>
        {file.status === 'done' ? (
          <FileInputItemDeleteCross onClick={deleteFile} css={{ cursor: 'pointer' }} />
        ) : (
          <LoadingSpinner />
        )}
      </span>
    </div>
  </FileInputItemContainer>
);

export default FileInputItem;

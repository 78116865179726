import React from 'react';
import axios from 'axios';
import FirstBlock from './blocks/FirstBlock';
import SecondBlock from './blocks/SecondBlock';
import SuccessBlock from './blocks/SuccessBlock';
import { FormBlockSection } from 'src/components/Form/elements';
import { useRestoreFormManager } from 'src/utils/RestoreForm';
import Cookies from 'js-cookie';
import { GRC } from 'src/components/Form';

const FORM_NAME = 'offer-form-completed';
const action = 'Offer';

const FormSteps = ({ step, setStep }) => {
  const manager = useRestoreFormManager();
  const [GRCtoken, setGRCToken] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [stepsData, setStepsData] = React.useState(() => {
    const restored = manager.get(FORM_NAME);
    if (restored) {
      return restored;
    }
    return {
      step1: null,
      step2: null,
    };
  });

  React.useEffect(() => {
    manager.set(FORM_NAME, stepsData);
  }, [manager, stepsData]);

  const setFormData = React.useCallback(
    async (stepName, data) => {
      if (!GRCtoken) {
        setError(true);
      }
      const allStepsData = { ...stepsData };
      Object.assign(allStepsData, { [stepName]: data });
      setStepsData(allStepsData);
      const currentStepNumber = parseInt(stepName[4], 10);
      if (currentStepNumber === Object.keys(stepsData).length) {
        try {
          const utmSource = Cookies.get('tracking') || Cookies.get('utm_source') || '';
          const gclid = Cookies.get('_gcl_aw') || '';
          const fbclid = Cookies.get('fbclid') || '';
          const referrer = Cookies.get('referrer') || '';

          const analytics = {
            utmSource,
            gclid,
            fbclid,
            referrer,
          };

          const data = {
            ...allStepsData.step1,
            ...allStepsData.step2,
            ...analytics,
          };
          const response = await axios.post(`${process.env.GATSBY_BASE_URL_NEW_API}/forms/offer`, {
            data,
            GRCtoken,
            action,
            source: document.location.href,
            track: {
              utmSource: Cookies.get('utm_source') || '',
              utmMedium: Cookies.get('utm_medium') || '',
              utmCampaign: Cookies.get('utm_campaign') || '',
              utmCampaignId: Cookies.get('utm_campaign_id') || '',
              adGroupName: Cookies.get('adgroup_name') || '',
              utmAdGroupId: Cookies.get('utm_adgroup_id') || '',
              utmTerm: Cookies.get('utm_term') || '',
              matchType: Cookies.get('matchtype') || '',
              utmContent: Cookies.get('utm_content') || '',
            },
          });
          if (stepName === 'step2' && response.data && response.data.status === 'OK') {
            setError(false);
            setStep('SUCCESS');
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
        }
      } else {
        setStep(currentStepNumber + 1);
        setError(false);
      }
    },
    [stepsData, setStep, GRCtoken]
  );

  const renderBlock = (step) => {
    switch (step) {
      case 1:
        return (
          <GRC setGRCToken={setGRCToken} action={action + '_' + step}>
            <FirstBlock
              onSubmit={(step1Data) => {
                setFormData('step1', step1Data);
              }}
              error={error}
              GRCtoken={GRCtoken}
            />
          </GRC>
        );
      case 2:
        return (
          <GRC setGRCToken={setGRCToken} action={action}>
            <SecondBlock
              onBackClicked={() => setStep(1)}
              onSubmit={(data) => {
                setFormData('step2', data);
              }}
              error={error}
              GRCtoken={GRCtoken}
            />
          </GRC>
        );
      case 'SUCCESS':
        return (
          <SuccessBlock
            onFormSuccess={() => {
              manager.reset('offer-step1');
              manager.reset('offer-step2');
              manager.reset('offer-form-completed');
            }}
          />
        );
      default:
        return null;
    }
  };
  return <FormBlockSection>{renderBlock(step)}</FormBlockSection>;
};

export default FormSteps;

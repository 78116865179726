import React, { useState } from 'react';
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import sizes from 'src/style/sizes';
import { mediaQueries } from 'src/style/breakpoints';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeader } from 'src/components/Form';
import { FormSteps } from 'src/components/OfferForm';

const OfferFormPage = ({ location }) => {
  const [step, setStep] = useState(1);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2B"
      pageName="Offre emploi"
      pageType="form"
      formStart={true}
      formType="offer"
      metaTitle="Déposer une offre d'emploi ou de stage | Le Reacteur"
      metaDescription="Vous êtes à la recherche de profils ? Recrutez nos étudiants qui ont été formés au développement Web et Mobile."
    >
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
          },
          [mediaQueries.desktopUp]: {
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <div
          css={{
            [mediaQueries.phoneOnly]: {
              marginTop: -160,
            },
            [mediaQueries.tabletPortraitUp]: {
              marginTop: -370,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: -330,
            },
            [mediaQueries.desktopUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          <FormHeader
            mainTitle="Déposer une offre"
            subTitle="Recrutez votre prochain talent tech."
          />
          <FormSteps step={step} setStep={setStep} />
        </div>
      </Section>
    </Layout>
  );
};

export default OfferFormPage;

import styled from 'src/style';
import colors from 'src/style/colors';
import fonts from 'src/style/fonts';
import { mediaQueries } from 'src/style/breakpoints';

export const FileInputContainer = styled.div(
  {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    outline: 'none',
    cursor: 'pointer',
    border: `1px dashed ${colors.purple} `,
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      maxWidth: 465,
    },
    [mediaQueries.tabletLandscapeUp]: {
      width: '100%',
      maxWidth: 545,
    },
  }
  /*   ({ errors }) => ({
    border: errors && errors.files ? '1px dashed red' : `1px dashed ${colors.purple} `,
  }) */
);

export const FileInputParagraph = styled.p({
  ...fonts.HKCompakt.bold,
  color: colors.midGrey,
  fontSize: '18px',
});

export const FileInputItemContainer = styled.div(({ status }) => ({
  display: 'inline-block',
  borderRadius: 5,
  marginBottom: 5,
  border: `1px ${status === 'done' ? colors.purpleDark : colors.midGrey} solid`,
  color: status === 'done' ? colors.purpleDark : colors.midGrey,
  padding: '13px 14px 13px 20px',
  [mediaQueries.tabletLandscapeUp]: {
    marginRight: 5,
  },
}));

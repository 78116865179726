import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SentenceSmall } from 'src/components/Typography';
import {
  InputForm,
  Label,
  NextBtn,
  RightInputWrapper,
  TwoInputWrapper,
  InputFormLarge,
  InputPhone,
} from 'src/components/Form/elements';
import { flags } from '../../Icons/Flags';
import ErrorMessage from '../../Form/ErrorMessage';
import { useRestoreFormManager } from 'src/utils/RestoreForm';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const FORM_NAME = 'offer-step1';

const FormBlockSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ obligatoire'),
  lastName: Yup.string().required('Champ obligatoire'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email non valide')
    .required('Champ obligatoire'),
  phoneNumber: Yup.string()
    .required('Champ obligatoire')
    .test({
      test: (num) => {
        if (num) {
          if (num.indexOf('+330') !== -1) {
            num = num.replace('+330', '+33');
            return isPossiblePhoneNumber(num);
          } else {
            return isPossiblePhoneNumber(num);
          }
        }
      },
      message: 'Numéro de téléphone non valide',
    }),
  company: Yup.string().required('Champ obligatoire'),
  website: Yup.string(),
});

const FirstBlock = ({ onSubmit, error, GRCtoken }) => {
  const manager = useRestoreFormManager();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    website: '',
    ...(manager.get(FORM_NAME) || {}),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormBlockSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        manager.set(FORM_NAME, values);
        onSubmit(values);
      }}
      // validate={(values) => {
      //   manager.set(FORM_NAME, values);
      // }}
    >
      {({ errors, handleChange, touched, isSubmitting, values, setFieldValue }) => {
        const isDisabled = () => {
          try {
            FormBlockSchema.validateSync(values);
            return false;
          } catch (error) {
            return true;
          }
        };
        return (
          <Form>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TwoInputWrapper>
                <div>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Prénom&nbsp;</Label>
                    <SentenceSmall>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="firstName"
                    errorStyle={errors.firstName && touched.firstName ? true : false}
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  <ErrorMessage
                    isVisible={errors.firstName && touched.firstName}
                    message={errors.firstName}
                  />
                </div>
                <RightInputWrapper>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Nom&nbsp;</Label>
                    <SentenceSmall>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="lastName"
                    errorStyle={errors.lastName && touched.lastName ? true : false}
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  <ErrorMessage
                    isVisible={errors.lastName && touched.lastName}
                    message={errors.lastName}
                  />
                </RightInputWrapper>
              </TwoInputWrapper>

              <TwoInputWrapper padding>
                <div>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Email&nbsp;</Label>
                    <SentenceSmall>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="email"
                    type="text"
                    errorStyle={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <ErrorMessage isVisible={errors.email && touched.email} message={errors.email} />
                </div>
                <RightInputWrapper>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Téléphone&nbsp;</Label>
                    <SentenceSmall>*</SentenceSmall>
                  </div>
                  <InputPhone
                    errors={errors}
                    touched={touched}
                    placeholder="Entrez votre numéro"
                    autoComplete="tel"
                    country="FR"
                    defaultCountry="FR"
                    flags={flags}
                    displayInitialValueAsLocalNumber={true}
                    value={values.phoneNumber}
                    onChange={(number) => {
                      setFieldValue('phoneNumber', number);
                    }}
                  />
                  <ErrorMessage
                    isVisible={errors.phoneNumber && touched.phoneNumber}
                    message={errors.phoneNumber}
                  />
                </RightInputWrapper>
              </TwoInputWrapper>

              <div css={{ paddingTop: 45 }}>
                <div css={{ display: 'flex', flexDirection: 'row' }}>
                  <Label>Entreprise&nbsp;</Label>
                  <SentenceSmall>*</SentenceSmall>
                </div>
                <InputFormLarge
                  name="company"
                  errorStyle={errors.company && touched.company ? true : false}
                  onChange={handleChange}
                  value={values.company}
                />
                <ErrorMessage
                  isVisible={errors.company && touched.company}
                  message={errors.company}
                />
              </div>

              <div css={{ paddingTop: 45 }}>
                <Label>Site web</Label>
                <InputFormLarge
                  name="website"
                  errorStyle={errors.website && touched.website ? true : false}
                  onChange={handleChange}
                  value={values.website}
                />
                <ErrorMessage
                  isVisible={errors.website && touched.website}
                  message={errors.website}
                />
              </div>

              <div css={{ paddingBottom: 10 }}>
                <NextBtn
                  type="submit"
                  disabledStyle={isDisabled()}
                  disabled={isSubmitting}
                  isFullWidth
                >
                  Suivant
                </NextBtn>
                {error && (
                  <ErrorMessage
                    isVisible={error}
                    message={
                      GRCtoken
                        ? 'Une erreur est survenue.'
                        : "Une erreur est survenue ! Vérifiez que vous n'êtes pas derrière un VPN. Si le problème persiste, merci de nous contacter directement."
                    }
                  />
                )}
              </div>
              <SentenceSmall>* Champ obligatoire</SentenceSmall>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FirstBlock;

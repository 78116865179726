import React from 'react';

const FileInputItemDeleteCross = ({ className, onClick }) => (
  <svg
    width="11"
    height="9"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M1.405.354L9.96 8.991M9.96.354L1.405 8.991"
      stroke="#190D33"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default FileInputItemDeleteCross;

import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import FileInputItem from './FileInputItem';
import { FileInputContainer, FileInputParagraph } from './elements';
import colors from 'src/style/colors';
import { generateID } from 'src/utils/helpers';

const FileInput = ({ onChange }) => {
  const [files, setFiles] = useState([]);
  const userId = uuidv4();

  React.useEffect(() => {
    onChange(
      files
        .filter((f) => f.status === 'done')
        .map((f) => {
          return { url: f.url, fileId: f.id };
        })
    );
  }, [files]);

  const uploadFiles = async (acceptedFiles) => {
    const filesQueue = acceptedFiles.map((file) => {
      return { localId: generateID(), file, name: file.name, status: 'pending' };
    });

    setFiles((prev) => {
      const next = [...prev];
      next.push(...filesQueue);
      return next;
    });

    filesQueue.forEach(async (file) => {
      const data = new FormData();
      data.append('file', file.file);
      data.append('reason', 'offer-form');
      data.append('userId', userId);

      try {
        const response = await axios.post(`${process.env.GATSBY_BASE_URL_NEW_API}/file`, data);
        setFiles((prev) => {
          const next = [...prev];
          const fileIndex = next.findIndex((f) => f.localId === file.localId);
          if (fileIndex >= 0) {
            next[fileIndex] = {
              ...next[fileIndex],
              status: 'done',
              id: response.data.id,
              url: response.data.url,
            };
          }
          return next;
        });
      } catch (err) {
        setFiles((prev) => {
          const next = [...prev];
          const fileIndex = next.findIndex((f) => f.localId === file.localId);
          if (fileIndex >= 0) {
            next[fileIndex] = {
              ...next[fileIndex],
              status: 'error',
              error: err,
            };
          }
          return next;
        });
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    uploadFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        css={{
          marginBottom: '15px',
        }}
      >
        {files.map((file, index) => (
          <FileInputItem
            deleteFile={async () => {
              const response = await axios.delete(
                `${process.env.GATSBY_BASE_URL_NEW_API}/file/${file.id}`
              );
              if (response.data.status === 'OK') {
                setFiles((prev) => {
                  const next = [...prev];
                  const fileIndex = next.findIndex((f) => f.localId === file.localId);
                  if (fileIndex >= 0) {
                    next.splice(fileIndex, 1);
                  }
                  return next;
                });
              }
            }}
            file={file}
            key={file.localId}
          />
        ))}
      </div>

      <FileInputContainer {...getRootProps()}>
        <input {...getInputProps()} multiple />
        <div css={{ width: '165px', textAlign: 'center' }}>
          {isDragActive ? (
            <FileInputParagraph>Déposer vos fichiers ici ...</FileInputParagraph>
          ) : (
            <FileInputParagraph>
              Déposez vos fichiers ici ou{' '}
              <span style={{ color: colors.purple, cursor: 'pointer' }}>
                parcourez vos fichiers
              </span>
            </FileInputParagraph>
          )}
        </div>
      </FileInputContainer>
    </div>
  );
};

export default FileInput;
